.Aa000 {
    background: rgba(255, 255, 255, 0.53);
    height: 100vh;
    width: 100vw;
    margin: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    z-index: 10;
    font-style: normal;
    opacity: 1;
    color: white;
    font-family: Arial, Helvetica, sans-serif;
    cursor: pointer;
}

.Aa000 .container {
    display: flex;
    flex-direction: column;
    width: fit-content;
    height: fit-content;
}

.Aa000 .header {
    display: flex;
    flex-direction: row;
    align-items: center;
    max-width: 100%;
    padding: .5rem;
    vertical-align: middle;
    align-items: center;
    justify-content: center;
}

.Aa000 .header-left .language {
    color: white;
    display: flex;
    border: 1px solid #000;
    border-radius: 9999px;
    transition: all 0.5s ease;
    box-shadow: 0 10px 15px rgba(0, 0, 0, 0.6);
    vertical-align: middle;
    margin-right: 2.5rem;
    margin-left: 2.5rem;
    padding: .25rem 1.4rem;
    margin-inline: 0;
    font-size: 15px;
}

.Aa000 .header-left .language p {
    margin: 0;
    padding-left: 0.9rem;
    padding-right: 0.9rem;
    cursor: pointer;
}

.Aa000 .header-left .language:hover {
    border-color: rgba(35, 193, 29, 0.688);
}

.Aa000 .header-left .language .language-left,
.Aa000 .header-left .language .language-middle,
.Aa000 .header-left .language .language-right {
    width: 33%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.Aa000 .header-left .language .language-right {
    justify-content: left;
}

.Aa000 .header-right .logo {
    width: 30%;
    height: 100%;
    margin: 0 auto;
}

.Aa000 .header-right img {
    padding-top: .25rem;
    padding-bottom: .25rem;
    width: 100%;
    max-width: 100%;
    height: auto;
    vertical-align: middle;
}

.Aa000 .form-content {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.Aa000 .form-content .form-content-top,
.Aa000 .form-content .form-content-middle,
.Aa000 .form-content .form-content-bottom {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 3rem;
}

.Aa000 .form-content .form-content-top,
.Aa000 .form-content .form-content-middle {
    margin-top: 1rem;
}

.Aa000 .form-content .form-content-footer {
    margin-top: 5rem;
    display: flex;
    align-items: center;
}

.Aa000 .form-content .form-content-footer a {
    color: rgba(0, 0, 0, 0.325);
}

#form-content-footer-unsub span {
    color: black;
    text-decoration: none;
}

#unsub-btn {
    color: #0000EE;
}

a:visited {
    color: #0000EE;
    text-decoration: none;
}

.Aa000 .form-content .form-content-top .logo {
    width: 60%;
    height: 100%;
}

.Aa000 .form-content .form-content-top img {
    padding-top: .25rem;
    padding-bottom: .25rem;
    width: 100%;
    max-width: 100%;
    height: auto;
    vertical-align: middle;
}

.Aa000 .form-content .title {
    margin-top: 3rem;
    /* margin: 0 auto; */
    padding: 0;
}

.Aa000 .form-content .title,
.Aa000 .form-content .form-content-middle .title {
    color: rgba(0, 0, 0, 0.468);
    font-size: 20px;
}

.Aa000 .form-content .title h4,
.Aa000 .form-content .form-content-middle .title h4 {
    margin-block: 0;
}

.Aa000 .form-content .form-content-middle .input {
    position: relative;
    border: 4px solid #67C162;
    border-radius: 50px;
    padding-top: .5rem;
    padding-bottom: .5rem;
    transition: all 0.3s ease-in-out;
    display: flex;
    flex-direction: row;
    width: fit-content;
}

.Aa000 .form-content .form-content-middle .input.vibrate {
    animation: shake 0.6s cubic-bezier(.36, .07, .19, .97) both;
}

.Aa000 .form-content .form-content-middle .input:before {
    content: '';
    position: absolute;
    top: 0px;
    left: -25px;
    width: 45px;
    height: 70px;
    background-color: transparent;
    transform: rotate(45deg);
    z-index: 1;
    clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 100%);
}

.Aa000 .form-content .form-content-middle .input input {
    width: 200px;
    height: 20px;
    font-size: 18px;
    outline: none;
    color: black;
    letter-spacing: 0.1rem;
    background-color: none;
    background-color: rgb(0, 0, 0, 0);
    border-width: 0px;
    padding: 16.5px 14px;
    text-align: start;
}

.Aa000 .form-content .form-content-middle .input:focus {
    outline: none;
}

.Aa000 .form-content .form-content-middle .input .phone-logo {
    width: 10rem;
    position: absolute;
    bottom: 0;
    left: 0;
    transform: translate(-50%, 15%);
    z-index: 100;
}


.Aa000 .form-content .form-content-middle .input .phone-logo img {
    width: 90px;
    height: 90px;
}

.Aa000 .form-content .form-content-middle .input .country-code {
    margin-left: 1rem;
    font-size: 18px;
    color: #999;
    letter-spacing: 0.1rem;
}

.Aa000 .form-content .form-content-middle .button {
    display: flex;
    flex-direction: column;
    color: black;
    margin-top: 1rem;
}

.Aa000 .form-content .form-content-middle .button button {
    width: 300px;
    font-size: 25px;
    outline: none;
    color: white;
    letter-spacing: 0.12rem;
    background-color: rgb(35, 193, 29, 0.688);
    border-radius: 8px;
    border-width: 0px;
    padding: 14px 14px;
    cursor: pointer;
    font-weight: 1000;
    margin-bottom: 0.3rem;
    transition: all 0.3s ease-in-out;
    animation: zoomInOut 1s infinite;
}

@keyframes zoomInOut {

    0%,
    100% {
        transform: scale(1);
    }

    50% {
        transform: scale(1.1);
    }
}

.Aa000 .form-content .form-content-middle .button button:hover {
    background-color: rgb(35, 193, 29, 0.688);
}

.Aa000 .form-content .form-content-middle .button span {
    display: flex;
    flex-direction: column;
    color: rgba(0, 0, 0, 0.51);
}

.Aa000 .form-content .form-content-bottom {
    width: 50%;
    color: rgb(0, 0, 0);
    line-height: 1.2rem;
    word-spacing: 0.03rem;
    text-align: center;
}

.Aa000 #terms {
    margin-top: .75rem;
    margin-bottom: .75rem;
    transition: .3s;
    padding: .5rem;
    cursor: pointer;
    font-size: 100%;
    align-items: flex-start;
    word-spacing: normal;
    letter-spacing: normal;
    color: rgb(0, 0, 0);
    font-weight: 300;
    font-family: Arial, Helvetica, sans-serif;
    background-image: none;
    text-transform: none;
    text-align: start;
    max-width: 100%;
    box-sizing: border-box;
}

.Aa000 #terms a {
    text-decoration: underline;
    color: white;
}

.Aa000 #terms ul {
    text-align: justify;
    font-size: 18px;
    line-height: 2;
    margin-block: 0;
    padding-inline: 0.5rem;
}

.Aa000 .arabic {
    direction: rtl;
}

.Aa000 .english {
    direction: ltr;
}

.Aa000 .english ul,
.Aa000 #terms ul,
.Aa000 .arabic ul {
    padding-inline: 0.5rem;
}

.Aa000 .warning-msg {
    visibility: hidden;
    opacity: 0;
    margin-top: -1rem;
    color: black;
    font-size: 16px;
    transition: all 0.5s ease-in-out;
}

.Aa000 .warning-msg.active {
    visibility: visible;
    opacity: 1;
    margin-top: 0.5rem;
}

.Aa000 .spinner,
.Aa000 .unsub-form .form-content-middle .unsub-details .spinner {
    display: none;
    justify-content: center;
    align-items: center;
    border: 3px solid rgba(0, 0, 0, 0.1);
    width: 20px;
    height: 20px;
    border-radius: 50%;
    border-left-color: #fff;
    animation: spin 2s linear infinite;
    margin-left: 20px;
}

@keyframes spin {
    to {
        transform: rotate(360deg);
    }
}

@keyframes shake {
    0% {
        transform: translateX(0);
    }

    10%,
    90% {
        transform: translateX(-10px);
    }

    20%,
    80% {
        transform: translateX(10px);
    }

    30%,
    50%,
    70% {
        transform: translateX(-10px);
    }

    40%,
    60% {
        transform: translateX(10px);
    }

    100% {
        transform: translateX(0);
    }
}

.Aa000 .arrows svg:first-child {
    margin-right: 2rem;
    animation: moveRight .75s linear infinite alternate;
}

.Aa000 .arrows svg:last-child {
    margin-left: 1.5rem;
    animation: moveLeft .75s linear infinite alternate;
}

@keyframes moveRight {
    0% {
        transform: translateX(50%);
    }

    100% {
        transform: translateX(-100%);
    }
}

@keyframes moveLeft {
    0% {
        transform: translateX(-50%);
    }

    100% {
        transform: translateX(100%);
    }
}

.kick-span button{
    width: 200px;
    font-size: 20px;
    outline: none;
    color: white;
    background-color: rgb(35, 193, 29, 0.688);
    border-radius: 8px;
    border-width: 0px;
    padding: 10px 8px;
    cursor: pointer;
    font-weight: 1000;
    margin-top: 3rem;
    transition: all 0.3s ease-in-out;
}

.Aa000 .popup {
    position: fixed;
    /* top: 0;
    left: 0; */
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
}

.Aa000 .popup-content {
    display: flex;
    flex-direction: column;
    background: #fff;
    padding: 20px;
    border-radius: 5px;
    text-align: center;
    align-items: center;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
    color: black;
}

.Aa000 .popup-content button {
    width: 200px;
    font-size: 15px;
    outline: none;
    color: white;
    letter-spacing: 0.12rem;
    background-color: rgb(35, 193, 29, 0.688);
    border-radius: 8px;
    border-width: 0px;
    padding: 14px 14px;
    cursor: pointer;
    font-weight: 1000;
    margin-bottom: 0.3rem;
    transition: all 0.3s ease-in-out;
}

.Aa000 .form-content .unsub-form {
    width: 100%;
}

.Aa000 .form-content .form-content-middle .unsub-inactive-msg,
.Aa000 .form-content .form-content-middle .unsub-details {
    color: black;
    margin-top: 1rem;
    margin-bottom: 1rem;
    font-size: 18px;
    width: 100%;
}

.Aa000 .form-content .form-content-middle .unsub-details div {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
}

.Aa000 .form-content .form-content-middle .unsub-details span {
    color: black;
}

.Aa000 .form-content .form-content-middle .unsub-details .unsub-details-value {
    color: gray;
}

hr {
    color: gray;
    opacity: 0.2;
    width: 100%;
}

.Aa000 .form-content .title .headup-title-unsub {
    text-align: left;
    /* padding-left: 1.5rem; */
}

@media screen and (max-width: 1350px) and (min-width: 650px) {

    .Aa000 .form-content .form-content-top,
    .Aa000 .form-content .form-content-middle,
    .Aa000 .form-content .form-content-bottom {
        margin-top: 2rem;
    }

    .Aa000 .form-content .form-content-middle {
        margin-top: 1rem;
    }

    .Aa000 .form-content .form-content-top .logo {
        width: 60%;
        height: 100%;
    }

    .Aa000 .form-content .form-content-bottom {
        width: 40%;
        margin-bottom: unset;
    }
}

@media screen and (max-width: 1070px) and (min-width: 864px) {
    .Aa000 .form-content .form-content-bottom {
        width: 50%;
    }
}

@media screen and (max-width: 863px) and (min-width: 650px) {
    .Aa000 {
        width: 100%;
        padding: .25rem;
        margin-left: auto;
        margin-right: auto;
    }

    .Aa000 .header-right {
        padding-left: 3rem;
    }

    .Aa000 .header-right .logo {
        width: 50%;
    }

    .Aa000 #terms {
        max-width: 85%;
    }

    .Aa000 .form-content .form-content-bottom {
        width: 65%;
    }
}

@media screen and (max-width: 650px) {
    .Aa000 {
        width: 100vw;
        margin-left: auto;
        margin-right: auto;
    }

    .Aa000 .form-content .form-content-middle {
        margin-top: 0.5rem;
    }

    .Aa000 .form-content .form-content-top {
        margin-top: 2rem;
    }

    .Aa000 .form-content .form-content-bottom {
        width: 96%;
        margin-bottom: unset;
        font-size: 12px;
        margin-top: 3rem;
    }

    .Aa000 .form-content .form-content-footer {
        font-size: 12px;
    }

    .Aa000 .header-right {
        /* padding-left: 3rem; */
    }

    .Aa000 .header-right .logo {
        width: 30%;
        /* margin-left: 25%; */
    }

    .Aa000 .arrows svg:first-child {
        margin-right: 2rem;
        animation: moveRight .75s linear infinite alternate;
    }

    .Aa000 .form-content .form-content-top .logo {
        width: 80%;
        height: 100%;
    }

    .Aa000 #terms {
        max-width: unset;
        margin-top: .5rem;
        margin-bottom: 1rem;
    }

    .Aa000 .form-content .form-content-middle .input input {
        width: 150px;
        font-size: 17px;
        padding: 15px 5px;
    }

    .Aa000 .form-content .form-content-middle .input .country-code {
        font-size: 17px;
    }

    .Aa000 .form-content .form-content-middle .input .phone-logo img {
        width: 60px;
        height: 60px;
    }

    .Aa000 .header {
        padding: unset;
    }

    .Aa000 .container {
        margin: 1rem 2rem;
    }

    .Aa000 .form-content .form-content-middle .input .phone-logo {
        transform: translate(-50%, 3%);
    }

    .Aa000 .form-content .form-content-middle .input .country-code {
        margin-left: 0.25rem;
    }

    .Aa000 .form-content .form-content-middle .input {
        display: flex;
        align-items: center;
    }

    @keyframes moveRight {
        0% {
            transform: translateX(75%);
        }

        100% {
            transform: translateX(15%);
        }
    }

    @keyframes moveLeft {
        0% {
            transform: translateX(-45%);
        }

        100% {
            transform: translateX(0%);
        }
    }
}

@media screen and (max-height: 750px) {
    .Aa000 {
        height: unset;
    }
}